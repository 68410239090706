import { Box, Button } from "@mui/material";
import EventPageStore from "./store";
import { toast } from "react-toastify";


const ActionButtonsSection: React.FC<{}> = () => {
    const handleDeleteEventModalOpen = () => {
        EventPageStore.setDeleteEventModalOpen(true);
    };

    const handleScanEventModalOpen = () => {
        EventPageStore.setScanEventModalOpen(true);
    }

    const handleOpenStartEventModal = () => {
        if (EventPageStore.practicipents.length === 0 && EventPageStore.eventMode != "messagesClientUi") {
            toast.error('יש להזין מסמך אקסל', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                theme: "light",
            });
            return;
        }

        EventPageStore.setStartEventModalOpen(true);
    };

    return (
        <Box mt={5} sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            {
                EventPageStore.isEventActive ? (
                    <>
                        <Button variant="contained" sx={{ borderRadius: '16px', width: '100%' }} onClick={handleScanEventModalOpen}>
                            סרוק אירוע
                        </Button>
                        <Button variant="contained" sx={{ borderRadius: '16px', width: '100%' }} onClick={handleDeleteEventModalOpen}>
                            סיים אירוע
                        </Button>
                    </>
                ) : (
                    <>
                        <Button variant="contained" sx={{ marginTop: "10px", width: "100%" }} onClick={handleDeleteEventModalOpen}>
                            מחק אירוע 🗑️
                        </Button>
                        <Button variant="contained" color="primary" sx={{ marginTop: "10px", width: "100%" }} onClick={handleOpenStartEventModal}>
                            🚀     שגר אירוע
                        </Button>
                    </>
                )
            }
        </Box>
    );
}

export default ActionButtonsSection;