import React, { useState, useEffect, useRef } from 'react';
import { Grid, Typography, Box, CircularProgress, Avatar } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import MyAlbumPageStore from '../MyAlbumPage/store';
import { getMyAlbumPageByCode, getPinCodePage } from '../../api';
import defaultBackgroundImage from "../../assets/images/default_background_image.png";
import { getEventPinCodeFromCookie } from '../MyAlbumPage/utils';
import { IPinCodeScreen } from '../../models/IPinCodeScreen';
import MemoraLogo from "../../assets/images/memora_logo.jpeg";
import { formatDate } from '../../utils/formatDate';
import PinField from 'react-pin-field';
import Loader from '../../components/Loader';

const PinCodeScreen: React.FC<{}> = () => {
    const { eventId, phoneNumber } = useParams();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [isVerifying, setIsVerifying] = useState(false);
    const [isTooManyTries, setIsTooManyTries] = useState(false);
    const [pinCodeScreenData, setPinCodeScreenData] = useState<IPinCodeScreen | undefined>(undefined);
    const [isInvalidCode, setIsInvalidCode] = useState(false);
    const pinInputRef = useRef<HTMLInputElement[]>([]);

    if (!eventId || !phoneNumber) throw new Error("משהו השתבש אנא נסה שנית מאוחר יותר");

    const pinCode = getEventPinCodeFromCookie(eventId) || "";

    useEffect(() => {
        const fetchAlbumPage = async () => {
            try {
                const data = await getMyAlbumPageByCode(eventId, pinCode || MyAlbumPageStore.pinCode, phoneNumber);
                if (data) {
                    navigate(`/myEventAlbum/${eventId}/${phoneNumber}`);
                }
            } catch (error: any) {
                if (error?.response?.status === 429) {
                    setIsTooManyTries(true);
                } else {
                    const pageData = await getPinCodePage(eventId, pinCode || MyAlbumPageStore.pinCode, phoneNumber);
                    setPinCodeScreenData(pageData);
                }
            } finally {
                setIsLoading(false);
            }
        };

        fetchAlbumPage();
    }, [eventId, phoneNumber]);

    const handleSubmit = async (value: string) => {
        setIsVerifying(true);
        setIsInvalidCode(false);
        try {
            const data = await getMyAlbumPageByCode(eventId, MyAlbumPageStore.pinCode, phoneNumber);
            if (data) {
                navigate(`/myEventAlbum/${eventId}/${phoneNumber}`);
            }
        } catch (error) {
            setIsInvalidCode(true);
        } finally {
            pinInputRef.current.forEach(input => (input.value = ""));
            setIsVerifying(false);
        }
    };

    const handlePinChange = (value: string) => {
        MyAlbumPageStore.setPinCode(value);
        if (value.length === 4) {
            handleSubmit(value); // Submit if length is 4
        }
    };

    if (isLoading) {
        return (
            <Loader />
        );
    }

    return (
        <Grid justifyContent="center" alignItems="center">
            <Grid item xs={12} style={{ position: 'relative' }}>
                <img
                    src={pinCodeScreenData?.backgroundImageUrl || defaultBackgroundImage}
                    alt="Event" style={{ width: '100%', height: '250px', objectFit: 'cover' }} />
                {pinCodeScreenData?.mainImageUrl &&
                    <Avatar
                        alt="User"
                        src={pinCodeScreenData?.mainImageUrl}
                        style={{
                            width: 80,
                            height: 80,
                            position: 'absolute',
                            bottom: -40,
                            right: 40,
                            border: '3px solid white',
                            backgroundColor: 'white',
                            objectFit: 'contain',
                            padding: 8,
                            boxShadow: '0px 12px 24px 0px rgba(18, 64, 31, 0.08)',
                        }}
                    />
                }
            </Grid>
            <Box p={3}>
                <Grid item xs={12}>
                    <Typography
                        variant="h4"
                        sx={{
                            marginTop: '15px',
                            marginBottom: '2px',
                            fontWeight: '800',
                            background: 'linear-gradient(90deg, #FF77C0 100%, #8745F3 66%, #70D4FF 100%)',
                            WebkitBackgroundClip: 'text',
                            WebkitTextFillColor: 'transparent',
                            backgroundClip: 'text',
                            textFillColor: 'transparent',
                        }}
                    >
                        {pinCodeScreenData?.eventName}
                    </Typography>
                    <Typography variant="h5" style={{ marginBottom: 8 }}>
                        {formatDate(pinCodeScreenData?.eventDate || "")}
                    </Typography>
                    <Typography variant="h6" style={{ marginBottom: 16 }}>
                        הכנו לכם אלבום אישי עם התמונות שלכם מהאירוע של {pinCodeScreenData?.eventName}, רוצים לראות ?
                    </Typography>
                    <Typography variant="h6" fontWeight="bold" style={{ marginBottom: 16 }}>
                        נא הזינו את הקוד שנשלח אליכם לוואטסאפ
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column" mt={4}>
                        <div dir='ltr'>
                            <PinField
                                ref={pinInputRef}
                                disabled={isVerifying || isTooManyTries}
                                length={4}
                                type="numeric"
                                inputMode="numeric"
                                style={{
                                    outline: "none",
                                    textAlign: "center",
                                    border: "1px #ADB8C9 solid",
                                    borderRadius: "14px",
                                    boxShadow: "0px 0px 10px 0px #ADB8C9",
                                    width: "55px",
                                    height: "55px",
                                    margin: "0 4px",
                                    fontSize: "16px",
                                }}
                                onChange={handlePinChange}
                            />
                        </div>
                        {isVerifying && (
                            <Box mt={2} display="flex" justifyContent="center" alignItems="center">
                                <CircularProgress size={24} />
                            </Box>
                        )}
                        {(isInvalidCode || isTooManyTries) && (
                            <Typography variant="body2" color="error" style={{ marginTop: 20 }}>
                                {isTooManyTries ? "יותר מידי ניסיונות אנא נסה שנית מאוחר יותר" : "קוד שגוי, אנא נסה שנית"}
                            </Typography>
                        )}
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Box mt={4} display="flex" justifyContent="center" width="100%">
                        <img src={MemoraLogo} alt="Memora Logo" style={{ width: 300, height: 'auto' }} />
                    </Box>
                </Grid>
            </Box>
        </Grid>
    );
};

export default PinCodeScreen;
