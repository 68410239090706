import { useQuery } from "react-query";
import { getMyAlbumPageBySelfie } from "../../../api";
import { IMyEventAlbumPage } from "../../../models/IMyEventAlbumPage";

export function useFetchAlbumBySelfie(eventId: string, selfie: File, phoneNumber?: string) {

    const { data, error, isLoading, refetch } = useQuery<IMyEventAlbumPage>(
        ["myAlbumPageByCode"],
        () => getMyAlbumPageBySelfie(eventId, selfie, phoneNumber),
        {
            enabled: true,
            retry: false,
        }
    );

    return { refetch, data, error, isLoading };
}