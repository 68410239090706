import React, { useState } from "react";
import { Button, Modal, Fade, TextField, Box, CircularProgress, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { createEvent, getUserPackages } from "../../../api";
import { useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import { IUserPackage } from "../../../models/IPackage";
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { DateTime } from "luxon";

interface CreateEventModalProps {
	open: boolean;
	onClose: () => void;
	refetch: () => void;
	isLoading: boolean;
}

const CreateEventModal: React.FC<CreateEventModalProps> = ({
	open,
	onClose,
	refetch,
	isLoading
}) => {
	const [eventName, setEventName] = useState('');
	const [packageName, setPackageName] = useState('basic');
	const [eventDate, setEventDate] = useState<DateTime | null>(null);
	const [errorMessage, setErrorMessage] = useState('');

	const { data, error, isLoading: isLoadingPackages } = useQuery(['userPackages'], () => getUserPackages(), { refetchOnWindowFocus: false, });
	const userPackages: IUserPackage[] = (data || []).filter((pkg: IUserPackage) => pkg.usesLeft > 0);

	const navigate = useNavigate();

	const handleSubmit = async (event: React.FormEvent) => {
		event.preventDefault();

		if (!eventName || !packageName || !eventDate) {
			setErrorMessage('All fields are required.');
			return;
		}

		try {
			const eventId = await createEvent(eventName, eventDate?.toISO() || '', packageName);
			await refetch();
			navigate(`/event/${eventId}`);
			onClose();
		} catch (e) {
			console.error("Error adding document: ", e);
			setErrorMessage('Failed to create event. Please try again.');
		}
	};

	if (error) {
		return <div>משהו השתבש אנא נסה שנית מאוחר יותר...</div>;
	}

	return (
		<Modal
			open={open}
			onClose={onClose}
			closeAfterTransition
			sx={{
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
			}}
		>
			<Fade in={open}>
				<Box
					sx={{
						backgroundColor: "background.paper",
						boxShadow: 5,
						padding: 2,
						textAlign: "center",
						direction: "rtl",
					}}
				>
					<h2>צור אירוע</h2>
					<form onSubmit={handleSubmit}>
						<div dir="rtl">
							<TextField
								label="שם האירוע"
								placeholder="שם האירוע"
								fullWidth
								dir="rtl"
								value={eventName}
								onChange={(e) => setEventName(e.target.value)}
								sx={{ marginBottom: 2 }}
								required
							/>
							<FormControl fullWidth sx={{ marginBottom: 2 }}>
								<InputLabel>בחר חבילה</InputLabel>
								<Select
									value={packageName}
									onChange={(e) => setPackageName(e.target.value)}
									label="בחר חבילה"
									defaultValue="basic"
								>
									{isLoadingPackages ? <CircularProgress /> : userPackages.map((pkg) => (
										<MenuItem key={pkg.name} value={pkg.name}>
											{pkg.name} - נשארו {pkg.usesLeft} שימושים
										</MenuItem>
									))}
								</Select>
							</FormControl>
							<LocalizationProvider dateAdapter={AdapterLuxon}>
								<DatePicker
									slotProps={{
										popper: { placement: 'auto' }
									}}
									label="תאריך אירוע"
									value={eventDate}
									onChange={(date) => setEventDate(date)}
								/>
							</LocalizationProvider>
							{errorMessage && <span style={{ color: 'red' }}>{errorMessage}</span>}
						</div>
						<Button
							variant="contained"
							color="primary"
							type="submit"
							sx={{ marginTop: 2 }}
							disabled={isLoading || isLoadingPackages}
						>
							{isLoading || isLoadingPackages ? <CircularProgress /> : "צור אירוע"}
						</Button>
					</form>
				</Box>
			</Fade>
		</Modal>
	);
};

export default CreateEventModal;
