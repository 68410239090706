import React from 'react';
import { Box, Paper, Typography } from '@mui/material';

interface IExistingImagesSectionProps {
    images: string[];
}

const ExistingImagesSection: React.FC<IExistingImagesSectionProps> = ({ images }) => {
    if (images.length === 0) return null;

    return (
        <Box mt={1}>
            <Typography variant="h5" sx={{ textAlign: 'left', fontWeight: "600" }}>
                תמונות מהאירוע
            </Typography>
            <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(4, 1fr)', gap: 2, marginTop: "10px" }}>
                {images.map((url, index) => (
                    <Paper key={index} sx={{ width: '180px', height: "180px", position: 'relative', borderRadius: '8px', overflow: 'hidden' }}>
                        <img src={url} alt={`image-${index}`} style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', objectFit: 'cover' }} />
                    </Paper>
                ))}
            </Box>
        </Box>
    );
};

export default ExistingImagesSection;
