import React from 'react';
import { Box, Typography, Button, Container } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import errorImage from '../assets/images/no_images_found.png';

interface ErrorPageProps { }

const ErrorPage: React.FC<ErrorPageProps> = () => {
    const navigate = useNavigate();

    const handleGoHome = () => {
        navigate('/');
    };

    return (
        <Container
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100vh',
                textAlign: 'center',
            }}
        >
            <Box
                component="img"
                src={errorImage}
                alt="404 Error"
                sx={{
                    maxWidth: '100%',
                    height: 'auto',
                    mb: 4,
                }}
            />
            <Typography
                variant="h1"
                fontWeight="bold"
                sx={{
                    fontSize: { xs: '3rem', sm: '4rem', md: '5rem' },
                    background: 'linear-gradient(90deg, #FF77C0, #8745F3, #70D4FF)',
                    WebkitBackgroundClip: 'text',
                    WebkitTextFillColor: 'transparent',
                    backgroundClip: 'text',
                    textFillColor: 'transparent',
                }}
            >
                404
            </Typography>
            <Typography
                variant="h6"
                sx={{
                    mb: 4,
                    color: 'text.secondary',
                    fontSize: { xs: '1.2rem', sm: '1.5rem' },
                }}
            >
                אופס! הדף שחיפשת לא קיים.
            </Typography>
            <Button
                variant="contained"
                onClick={handleGoHome}
                sx={{
                    background: 'linear-gradient(90deg, #FF77C0 100%, #8745F3 66%, #70D4FF 100%)',
                    padding: '12px 24px',
                    fontSize: { xs: '1rem', sm: '1.2rem' },
                    '&:hover': {
                        background: '#763fc1',
                    },
                }}
            >
                חזרה לדף הבית
            </Button>
        </Container>
    );
};

export default ErrorPage;
