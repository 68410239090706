import { Box, Button, Grid, Typography, Card, CardContent, CardActions } from '@mui/material';
import { packages } from '../../assets/packages';


const MyPackages = () => {

    return (
        <Box sx={{ flexGrow: 1, m: 2 }}>
            <Typography variant="h4" fontWeight="bold" textAlign="start" mb={2}>
                חבילות שלי
            </Typography>
            <Grid container spacing={2}>
                {packages.map((packageData) => (
                    <Grid item xs={12} sm={6} md={4} key={packageData.title}>
                        <Card sx={{ display: 'flex', flexDirection: 'column', height: 450 }}>
                            <CardContent sx={{ flexGrow: 1 }}>
                                <Typography variant="h5" fontWeight="bold">
                                    {packageData.title}
                                </Typography>
                                <Typography variant="body1" sx={{ my: 1.5 }}>
                                    {packageData.description}
                                </Typography>
                                <Typography variant="h6" color="secondary">
                                    ₪{packageData.discountPrice} <span style={{ textDecoration: 'line-through' }}>₪{packageData.originalPrice}</span>
                                </Typography>
                                <ul>
                                    {packageData.features.map((feature, index) => (
                                        <Typography component="li" variant="body2" key={index}>
                                            {feature}
                                        </Typography>
                                    ))}
                                </ul>

                            </CardContent>
                            <CardActions sx={{ justifyContent: 'space-between', padding: 2 }}>
                                <Typography sx={{ mt: 2 }} color="primary" fontWeight="bold">
                                    כמות: {0}
                                </Typography>
                                <Button variant="contained" color="primary">
                                    {packageData.buttonText}
                                </Button>
                            </CardActions>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
}


export default MyPackages;
