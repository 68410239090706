import { useState, useRef, useEffect } from "react";
import { Grid, Typography, Box, Button } from "@mui/material";
import PinField from "react-pin-field"; // Assuming you're using a third-party library like 'react-pin-field'
import ImagesUploader from "../../components/ImagesUploader";
import ExistingImagesSection from "../EventPage/ExistingImagesSection";
import { useParams } from "react-router-dom";
import Cookies from 'js-cookie'; // Import js-cookie for cookie handling
import { getImagesUploadPage } from "../../api";
import { formatDate } from "../../utils/formatDate";
import MemoraCover from "./components/MemoraCover";

interface ImagesUploadPage {
    backgroundImageUrl: string;
    eventName: string;
    fewImagesFromTheEvent: string[];
    eventDate: string;
}

const PhotographersImagesUpload: React.FC<{}> = () => {
    const { eventId } = useParams();
    const [isValidated, setIsValidated] = useState(false); // Validation state
    const [pinCode, setPinCode] = useState(''); // Store pin code
    const [errorMessage, setErrorMessage] = useState('');
    const pinInputRef = useRef(null);
    const [isVerifying, setIsVerifying] = useState(false);
    const [isTooManyTries, setIsTooManyTries] = useState(false);
    const [eventData, setEventData] = useState<ImagesUploadPage | null>(null); // Store the response data

    // Check if the pin code for the event is already saved in cookies
    useEffect(() => {
        const savedPinCode = Cookies.get(`pinCode_${eventId}`);
        if (savedPinCode) {
            setPinCode(savedPinCode);
            verifyPinCode(savedPinCode); // Automatically verify the saved pin code
        }
    }, [eventId]);

    const handlePinChange = (value: string) => {
        setPinCode(value);
    };

    // Function to verify the pin code
    const verifyPinCode = async (pin: string) => {
        setIsVerifying(true);
        try {
            const data = await getImagesUploadPage(eventId!, pin);
            if (data) {
                setIsValidated(true);
                setErrorMessage('');
                setEventData(data);
                Cookies.set(`pinCode_${eventId}`, pin, { expires: 7 }); // Save pin code in cookie for 7 days
            } else {
                setErrorMessage('Invalid pin code. Please try again.');
            }
        } catch (error: any) {
            if (error.response?.status === 429) {
                setErrorMessage('Too many attempts. Please try again later.');
                setIsTooManyTries(true);
            } else {
                setErrorMessage('Failed to validate pin code.');
            }
        } finally {
            setIsVerifying(false);
        }
    };

    const handleVerifyPinCode = () => {
        verifyPinCode(pinCode); // Verify using the current pin code
    };

    return (
        <Grid container sx={{ width: '100vw', height: '100vh', overflow: 'hidden', justifyContent: 'center', flexDirection: { xs: 'column', md: 'row' } }}>
            <Box sx={{ width: '100%', height: '100%', overflow: 'hidden', display: 'flex', flexDirection: { xs: 'column', md: 'row' } }}>
                <Grid
                    item
                    xs={12}
                    md={6}
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: '100%',
                        width: { xs: '100%', md: '50%' },
                    }}
                >
                    <MemoraCover />
                </Grid>
                <Grid
                    item
                    xs={12}
                    md={6}
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    sx={{ height: '100%', backgroundColor: '#fff', padding: { xs: '10px', md: '30px' }, borderRadius: { xs: '0', md: '0 14px 14px 0' } }}
                >
                    {!isValidated ? (
                        <Box textAlign="center" sx={{ width: '80%' }}>
                            <Typography variant="h4" fontWeight="bold" sx={{ marginBottom: 2 }}>
                                הזן את הקוד שקיבלת
                            </Typography>
                            <div dir='ltr'>
                                <PinField
                                    ref={pinInputRef}
                                    disabled={isVerifying || isTooManyTries}
                                    length={4}
                                    type="numeric"
                                    inputMode="numeric"
                                    style={{
                                        outline: "none",
                                        textAlign: "center",
                                        border: "1px #ADB8C9 solid",
                                        borderRadius: "14px",
                                        boxShadow: "0px 0px 10px 0px #ADB8C9",
                                        width: "55px",
                                        height: "55px",
                                        margin: "0 4px",
                                        fontSize: "16px",
                                    }}
                                    onChange={handlePinChange}
                                />
                            </div>
                            <Button
                                variant="contained"
                                style={{
                                    marginTop: "30px",
                                    width: "30%",
                                    borderRadius: "12px",
                                    background: 'linear-gradient(157.4deg, #FF77C0 7.03%, #8745F3 48.3%, #70D4FF 95.95%)',
                                    color: 'white',
                                    padding: "5px 10px",
                                    border: 'none',
                                    fontSize: '16px',
                                    fontWeight: 'bold',
                                    cursor: 'pointer',
                                    textAlign: 'center',
                                    textTransform: 'none',
                                    boxShadow: 'none',
                                }}
                                onClick={handleVerifyPinCode}
                                disabled={isVerifying || isTooManyTries}
                            >
                                {isVerifying ? 'מאמת...' : 'אישור'}
                            </Button>
                            {errorMessage && <Typography color="error" sx={{ marginTop: 2 }}>{errorMessage}</Typography>}
                        </Box>
                    ) : (
                        <>
                            <Box textAlign="center" sx={{ marginBottom: 2, width: '80%' }}>
                                <Typography variant="h3" fontWeight="bold">
                                    {eventData?.eventName}
                                </Typography>
                                <Typography variant="h6">{formatDate(eventData?.eventDate || "")}</Typography>
                            </Box>
                            <Grid
                                container
                                justifyContent="center"
                                alignItems="center"
                                sx={{
                                    width: '80%',
                                    flexGrow: 1,
                                }}
                            >
                                <ImagesUploader width="100%" isPhotograper={true} pinCode={pinCode} />
                            </Grid>
                        </>
                    )}
                </Grid>
            </Box>
        </Grid>
    );
};

export default PhotographersImagesUpload;
