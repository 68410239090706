import { Box, Typography } from "@mui/material";
interface StatBlockProps {
    label: string;
    count: number;
}

const StatBlock: React.FC<StatBlockProps> = ({ label, count }) => {
    return (
        <Box sx={{ minWidth: 240, borderRadius: 2, backgroundColor: "#f5f5f5", padding: "15px" }}>
            <Box>
                <Typography sx={{ fontSize: 14, fontWeight: "bold" }} gutterBottom>
                    {label}
                </Typography>
                <Typography variant="h5" fontWeight="600" component="div">
                    {count}
                </Typography>
            </Box>
        </Box>
    );
}

export default StatBlock;