import React from "react";
import { IconButton } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

interface ArrowButtonProps {
    direction: "left" | "right";
    onClick: () => void;
    isVisible: boolean;
}

const ArrowButton: React.FC<ArrowButtonProps> = ({ direction, onClick, isVisible }) => {
    if (!isVisible) return null;

    return (
        <IconButton
            onClick={onClick}
            sx={{
                color: "black",
                backgroundColor: "white",
                position: "absolute",
                [direction === "left" ? "left" : "right"]: 16,
                width: 48,
                height: 48,
                borderRadius: "50%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                '&:hover': {
                    backgroundColor: "white", // Keep the background white on hover
                },
            }}
        >
            {direction === "right" ? <ChevronLeftIcon fontSize="large" /> : <ChevronRightIcon fontSize="large" />}
        </IconButton>
    );
};

export default ArrowButton;
