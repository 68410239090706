import { Grid, Skeleton, Box, Tooltip, Typography } from "@mui/material";
import { AgGridReact } from "ag-grid-react";
import { IEvent } from "../../../models/IEvent";
import { DateTime } from 'luxon'
import { useMemo } from "react";
import { Link } from "react-router-dom";
import { RemoveRedEye } from "@mui/icons-material";
import './style.css'; // Ensure you import the custom CSS

interface EventsTableProps {
    events: IEvent[];
}

const EventsTable: React.FC<EventsTableProps> = ({ events }) => {
    const rowData = useMemo(() => events.map((event) => {
        const rawTimestamp = { seconds: event.createdAt._seconds, nanoseconds: event.createdAt._nanoseconds };
        const jsDate = new Date(rawTimestamp.seconds * 1000 + rawTimestamp.nanoseconds / 1000000);
        const luxonDateTime = DateTime.fromJSDate(jsDate);
        const formattedDate = luxonDateTime.setLocale('he').toFormat('dd/MM/yyyy HH:mm');

        return {
            creationDate: formattedDate,
            eventName: event.name,
            location: event.eventType,
            participants: event.practicipants?.length,
            isActive: event.isActive,
            actions: { id: event.id }
        }
    }), [events]);
    const columnDefs = useMemo(() => [
        { field: "eventName", headerName: "שם האירוע", flex: 1, minWidth: 200, cellStyle: { fontWeight: '500' } },
        { field: "creationDate", headerName: "תאריך יצירה", flex: 1, minWidth: 200 },
        { field: "location", headerName: "מיקום האירוע", flex: 1, minWidth: 200 },
        { field: "participants", headerName: "מספר משתתפים", flex: 1, minWidth: 200 },
        {
            field: "isActive", headerName: "סטאטוס", flex: 1, minWidth: 200, cellRenderer: (params: any) => {
                const isActive = params.data.isActive;
                return (<Typography variant="body1" mt={1} fontWeight={isActive ? "600" : "300"} color={isActive ? "green" : "black"}> {isActive ? "פעיל" : "לא פעיל"} </Typography>)
            }
        },
        {
            flex: 1,
            minWidth: 200,
            field: "actions",
            headerName: "",
            cellRenderer: (params: any) => {
                return (
                    <Typography
                        component={Link}
                        to={`/event/${params.data.actions.id}`}
                        sx={{
                            fontWeight: 'bold',
                            color: 'grey.600',  // Using a theme color
                            textDecoration: 'none',
                        }}
                    >
                        ערוך אירוע
                    </Typography>
                );
            }
        }
    ], []);
    return (
        <Grid container item xs={12} style={{ height: 800, width: '100%' }}>
            <div className="ag-theme-alpine" style={{ height: '100%', width: '100%' }}>
                <AgGridReact
                    rowStyle={{ border: "none" }}
                    loadingCellRenderer={<Skeleton />}
                    enableRtl={true}
                    rowData={rowData}
                    columnDefs={columnDefs as any}
                />
            </div>
        </Grid>
    );
}

export default EventsTable;
