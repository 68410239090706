import { Box, Divider, Grid, Typography } from "@mui/material";
import memoraLogoWhite from "../../../assets/images/memora-logo-whita-a.png";
import instagramWhiteButton from "../../../assets/images/instagram_white_button.png";
import whatsappWhiteButton from "../../../assets/images/whatsapp_white_button.png";
import { Credits } from "../../../models/Credits";

const BottomFooter: React.FC<{ credits: Credits }> = ({ credits }) => {
    return (
        <Box
            component="footer"
            sx={{
                background: "rgba(135, 69, 243, 1)",
                width: '100%',
                mt: 'auto',
                padding: "13px",
            }}
        >
            <Grid container justifyContent="center" alignItems="center">
                <Grid item xs={12} textAlign="center">
                    <img
                        src={memoraLogoWhite}
                        alt="footer"
                        style={{ width: '150px', height: 'auto', margin: '0 auto' }}
                    />
                </Grid>
                <Divider
                    sx={{
                        height: "0.5px",
                        background: "white",
                        width: "90%",
                        mx: "auto",
                        mt: 2,
                        mb: 1,
                    }}
                />
                <Box>
                    <Box sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        textAlign: "center",
                        gap: 1, // Adds spacing between items
                        mb: 2, // Margin at the bottom of the section
                    }}>
                        {credits?.length > 0 && credits.some((credit) => credit.value) && (
                            <Box>
                                <Typography color="white" variant="h6" fontWeight="bold">
                                    צלמי האירוע:
                                </Typography>
                                {credits
                                    .filter((credit) => credit.value) // Ensure only credits with non-empty values are displayed
                                    .map((credit, index) => (
                                        <Typography key={index} color="white" variant="body1">
                                            <strong>{credit.label}: </strong>
                                            <a
                                                href={credit.link}
                                                target="_blank"
                                                rel="noreferrer"
                                                style={{ textDecoration: "none", color: "white" }}
                                            >
                                                {credit.value}@
                                            </a>
                                        </Typography>
                                    ))}
                            </Box>
                        )}
                    </Box>
                    <Box sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        textAlign: "center",
                        mb: 1
                    }}>
                        <Typography variant="h6" color="white">
                            service@memora-ai.cloud
                        </Typography>
                    </Box>
                </Box>
                <Grid container justifyContent="center" spacing={2}>
                    <Grid item>
                        <img
                            onClick={() => window.open("https://wa.me/972515170242")}
                            src={whatsappWhiteButton}
                            alt="WhatsApp"
                            style={{ width: "35px", height: "35px", cursor: "pointer" }}
                        />
                    </Grid>
                    <Grid item>
                        <img
                            src={instagramWhiteButton}
                            onClick={() => window.open("https://www.instagram.com/memora.ai/")}
                            alt="Instagram"
                            style={{ width: "35px", height: "35px", cursor: "pointer" }}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
}

export default BottomFooter;
