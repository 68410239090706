import React, { useState, useEffect, useCallback } from "react";
import { observer } from "mobx-react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import 'react-lazy-load-image-component/src/effects/blur.css';
import MyAlbumPageStore from "../../store";
import "./CustomCheckbox.css";
import { AlbumImage } from "../../../../models/AlbumImage";

// Memoized Image List Item component to prevent unnecessary re-renders
const ImageGridItem = React.memo(({ img, isSelected, handleImageClick, handleImageError }: any) => {
    const [isImageLoaded, setIsImageLoaded] = useState(false);

    return (
        <div style={{ position: 'relative' }}>
            {img.error ? (
                <div
                    style={{
                        width: '100%',
                        height: '300px',
                        backgroundColor: '#f0f0f0',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        borderRadius: '12px',
                        color: '#888',
                    }}
                >
                    תמונה לא זמינה
                </div>
            ) : (
                <LazyLoadImage
                    alt="albumImage"
                    src={img.displayUrl}
                    effect="blur"
                    onClick={() => handleImageClick(img)}
                    onLoad={() => setIsImageLoaded(true)}
                    onError={handleImageError}
                    style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                        borderRadius: '12px',
                        cursor: 'pointer',
                    }}
                />
            )}
            {isImageLoaded && MyAlbumPageStore.isSelectImagesMode && (
                <div className="checkbox-container">
                    <input
                        type="checkbox"
                        className="custom-checkbox"
                        id={`checkbox-${img.key}`}
                        checked={isSelected}
                        onChange={() => {
                            MyAlbumPageStore.selectImage(img);
                        }}
                    />
                    <label className="custom-label" htmlFor={`checkbox-${img.key}`}></label>
                </div>
            )}
        </div>
    );
});

interface ImagesListProps {
    handleImageClick: (image: AlbumImage) => void;
}

const ImagesList: React.FC<ImagesListProps> = observer(({ handleImageClick }) => {
    const images = MyAlbumPageStore.images;

    const [imageError, setImageError] = useState<boolean[]>([]);

    useEffect(() => {
        setImageError(new Array(images.length).fill(false));
    }, [images.length]);

    const handleImageError = (index: number) => {
        const updatedErrorState = [...imageError];
        updatedErrorState[index] = true;
        setImageError(updatedErrorState);
    };

    const isSelected = (image: AlbumImage) => {
        return MyAlbumPageStore.selectedImages.some(
            (selectedImage) => selectedImage.displayUrl === image.displayUrl
        );
    };

    const calcImagesInARow = useCallback(() => {
        const imagesCount = images.length;
        if (imagesCount <= 10) {
            return 1;
        } else if (imagesCount <= 20) {
            return 2;
        } else {
            return 3;
        }
    }, [images]);


    return (
        <div
            style={{
                display: 'grid',
                gridTemplateColumns: `repeat(${calcImagesInARow()}, 1fr)`,
                gap: '8px',
                padding: '16px',
                maxHeight: '600px',
                overflowY: 'auto',
            }}
        >
            {images.map((img, index) => (
                <div key={index}>
                    <ImageGridItem
                        img={img}
                        isSelected={isSelected(img)}
                        handleImageClick={handleImageClick}
                        handleImageError={() => handleImageError(index)}
                    />
                </div>
            ))}
        </div>
    );
});

export default ImagesList;
