import React from "react";
import { Box, Typography, CircularProgress, SxProps, Theme } from "@mui/material";
import { SvgIconProps } from "@mui/material/SvgIcon";

interface GradientIconButtonProps {
    label: string;
    Icon: React.ComponentType<SvgIconProps>; // Use ComponentType for SVG Icons
    onClick: () => void;
    sx?: SxProps<Theme>;
    disabled?: boolean;
    isLoading?: boolean;  // New prop for showing loading spinner
}

const GradientIconButton: React.FC<GradientIconButtonProps> = ({ label, Icon, onClick, sx = {}, disabled, isLoading }) => {
    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            onClick={onClick}
            sx={{
                cursor: disabled || isLoading ? "default" : "pointer",  // Disable cursor when loading or disabled
                opacity: disabled ? 0.5 : 1,
                width: 70,
                height: 70,
                borderRadius: "50%",
                border: "1px solid transparent", // Initial border for gradient effect
                backgroundImage: "linear-gradient(#f8f8f8, #f8f8f8), linear-gradient(157.4deg, #FF77C0, #8745F3, #70D4FF)",
                backgroundOrigin: "border-box",
                backgroundClip: "padding-box, border-box", // Gradient border
                ...sx,
            }}
        >
            {isLoading ? (
                <CircularProgress size={24} />
            ) : (
                <Icon sx={{ fontSize: 28, color: "black" }} />
            )}
            <Typography variant="body2" fontWeight={700}>{label}</Typography>
        </Box>
    );
};

export default GradientIconButton;
