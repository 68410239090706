import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Box, Button, CircularProgress, Paper, TextField } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { signup } from '../../api';
import Ajv from "ajv";
import addFormats from "ajv-formats";
import { errorToast } from '../../toastAlerts';

const ajv = new Ajv({ allErrors: true, $data: true });
addFormats(ajv);

const schema = {
    type: "object",
    properties: {
        email: { type: "string", format: "email" },
        password: { type: "string", minLength: 8 },
        confirmPassword: { const: { $data: "1/password" } },
        phone: { type: "string", minLength: 10 },
        firstName: { type: "string", minLength: 1 },
        lastName: { type: "string", minLength: 1 }
    },
    required: ["email", "password", "confirmPassword", "phone", "firstName", "lastName"],
    additionalProperties: false
};

const validate = ajv.compile(schema);

export const SignupForm: React.FC = () => {
    const [isLoading, setIsLoading] = useState(false);
    const { register, handleSubmit, setError, formState: { errors } } = useForm({
        mode: "onBlur",
        criteriaMode: "all"
    });
    const navigate = useNavigate();

    const onSubmit = async (data: Record<string, string>) => {
        if (!validate(data)) {
            validate.errors?.forEach(error => {
                setError(error.instancePath.substring(1), { type: "manual", message: error.message });
            });
            return;
        }

        const formattedPhoneNumber = `+${data.phone.replace('0', '972')}`;
        try {
            setIsLoading(true);
            const response = await signup(data.email, data.password, formattedPhoneNumber, data.firstName, data.lastName);

            if (response?.status === 200 && response.data) {
                const user = response.data;
                localStorage.setItem('user', JSON.stringify(user));
                navigate('/events');
                return;
            } else if (response?.status === 400) {
                if (response.data.errorCode === 'EMAIL_ALREADY_EXISTS') {
                    setError("email", { type: "manual", message: "Email already exists" });
                } else if (response.data.errorCode === 'WEAK_PASSWORD') {
                    setError("password", { type: "manual", message: "Password is too weak" });
                } else if (response.data.errorCode === 'PHONE_NUMBER_ALREADY_EXISTS') {
                    setError("phone", { type: "manual", message: "Phone number already exists" });
                } else if (response.data.errorCode === 'INVALID_MAIL') {
                    setError("email", { type: "manual", message: "Invalid email" });
                }
            }

            errorToast("הרשמה נכשלה");
        } catch (error: any) {
            errorToast("הרשמה נכשלה");
        } finally {
            setIsLoading(false);
        }
    };
    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column', // Ensures vertical alignment
            justifyContent: 'center', // Centers content vertically in the container
            alignItems: 'center', // Centers content horizontally
            minHeight: '100vh', // Takes at least the full height of the viewport
            width: '100vw' // Takes the full width of the viewport
        }}>
            <Paper elevation={3} sx={{ padding: 3, width: "500px", boxSizing: 'border-box' }}>
                <h2>הרשמה</h2>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <TextField
                        fullWidth
                        label="אימייל"
                        variant="outlined"
                        {...register("email", { required: "Email is required" })}
                        error={!!errors.email}
                        helperText={(errors.email && typeof errors.email.message === 'string') ? errors.email.message : ""}
                        sx={{ marginBottom: 2 }}
                    />
                    <TextField
                        fullWidth
                        label="טלפון"
                        variant="outlined"
                        {...register("phone", { required: "Phone number is required" })}
                        error={!!errors.phone}
                        helperText={(errors.phone && typeof errors.phone.message === 'string') ? errors.phone.message : ""}
                        sx={{ marginBottom: 2 }}
                    />
                    <TextField
                        fullWidth
                        label="שם פרטי"
                        variant="outlined"
                        {...register("firstName", { required: "First name is required" })}
                        error={!!errors.firstName}
                        helperText={(errors.firstName && typeof errors.firstName.message === 'string') ? errors.firstName.message : ""}
                        sx={{ marginBottom: 2 }}
                    />
                    <TextField
                        fullWidth
                        label="שם משפחה"
                        variant="outlined"
                        {...register("lastName", { required: "Last name is required" })}
                        error={!!errors.lastName}
                        helperText={(errors.lastName && typeof errors.lastName.message === 'string') ? errors.lastName.message : ""}
                        sx={{ marginBottom: 2 }}
                    />
                    <TextField
                        fullWidth
                        label="סיסמה"
                        type="password"
                        variant="outlined"
                        {...register("password", { required: "Password is required" })}
                        error={!!errors.password}
                        helperText={(errors.password && typeof errors.password.message === 'string') ? errors.password.message : ""}
                        sx={{ marginBottom: 2 }}
                    />
                    <TextField
                        fullWidth
                        label="אישור סיסמה"
                        type="password"
                        variant="outlined"
                        {...register("confirmPassword", { required: "Confirm password is required" })}
                        error={!!errors.confirmPassword}
                        helperText={(errors.confirmPassword && typeof errors.confirmPassword.message === 'string') ? errors.confirmPassword.message : ""}
                        sx={{ marginBottom: 2 }}
                    />
                    <Button type="submit" variant="contained" color="primary" fullWidth>{isLoading ? <CircularProgress /> : "הרשמה"}</Button>
                </form>
            </Paper>
        </Box>
    );
};
