import React, { useContext } from 'react';
import { Sidebar, Menu, MenuItem } from 'react-pro-sidebar';
import { AuthContext } from '../../Context/AuthContext';
import { Avatar, Box, Typography } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import { CalendarMonth, Home, Logout, Settings, Store } from '@mui/icons-material';

const SideNavbar: React.FC = () => {
	const { currentUser, signOut } = useContext(AuthContext);

	if (!currentUser?.token) {
		return null;
	}

	return (
		<Sidebar rtl>
			<Box display="flex" alignItems="center" gap={1} style={{ padding: '20px' }}>
				<Avatar
					src="https://gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50.jpg"
					alt="גיא יהושע"
					style={{ width: 40, height: 40, marginRight: '10px' }}
				/>
				<Typography variant="h6" style={{ fontWeight: 'bold' }}>
					<strong>שלום,</strong>{currentUser?.firstName} {currentUser?.fammilyName}
				</Typography>
			</Box>
			<Menu>
				<Link to="/" style={{ textDecoration: 'none', color: 'inherit' }}>
					<MenuItem icon={<Home />}>דאשבורד</MenuItem>
				</Link>
				<Link to="/events" style={{ textDecoration: 'none', color: 'inherit' }}>
					<MenuItem icon={<CalendarMonth />}>האירועים שלי</MenuItem>
				</Link>
				<Link to="/packages" style={{ textDecoration: 'none', color: 'inherit' }}>
					<MenuItem icon={<Store />}>החבילות שלי</MenuItem>
				</Link>
				<Link to="/settings" style={{ textDecoration: 'none', color: 'inherit' }}>
					<MenuItem icon={<Settings />}>הגדרות</MenuItem>
				</Link>
				<MenuItem icon={<Logout />} onClick={signOut}>התנתק</MenuItem>
			</Menu>
		</Sidebar>
	);
};

export default SideNavbar;
