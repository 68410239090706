import React from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import Events from "./pages/MyEventsPage";
import EventPage from "./pages/EventPage";
import { LoginForm } from "./pages/LoginPage";
import { SignupForm } from "./pages/SignUpPage";
import ForgotMyPasswordPage from "./pages/ResetPasswordPage";
import SideNavbar from "./components/NavBar";
import MyPackages from "./pages/MyPackages";
import { ToastContainer } from "react-toastify";
import PinCodeScreen from "./pages/PinCodeScreen";
import { RequireAuth } from "./RequireAuth";
import GetSelfiePage from "./pages/GetSelfiePage";
import MyAlbumPageBySelfie from "./pages/MyAlbumPage/MyAlbumPageBySelfie";
import MyAlbumPageByCode from "./pages/MyAlbumPage/MyAlbumPageByCode";
import MyAlbumPageBySharedLink from "./pages/MyAlbumPage/MyAlbumPageBySharedLink";
import PhotographersImagesUpload from "./pages/PhotographersImagesUpload";

function App() {
  const location = useLocation();

  // Define routes where the sidebar should be displayed (routes that require authentication)
  const authRoutes = [
    "/events",
    "/packages",
    "/event/:eventId",
  ];

  // Check if the current path matches any route in the authRoutes array
  const shouldDisplaySidebar = authRoutes.some((path) =>
    new RegExp(`^${path.replace(/:\w+/g, "\\w+")}$`).test(location.pathname)
  );

  return (
    <div style={{ display: "flex", minHeight: "100vh", direction: "rtl" }}>
      {shouldDisplaySidebar && <SideNavbar />}
      <Routes>
        <Route path="/" element={<LoginForm />} />
        <Route path="/login" element={<LoginForm />} />
        <Route path="/signup" element={<SignupForm />} />
        <Route path="/myEventAlbum/:eventId/:phoneNumber" element={<MyAlbumPageByCode />} />
        <Route path="/reset-password" element={<ForgotMyPasswordPage />} />
        <Route path="/events" element={<RequireAuth><Events /></RequireAuth>} />
        <Route path="/packages" element={<RequireAuth><MyPackages /></RequireAuth>} />
        <Route path="/event/:eventId" element={<RequireAuth><EventPage /></RequireAuth>} />
        <Route path="/pin-code/:eventId/:phoneNumber" element={<PinCodeScreen />} />
        <Route path="/selfie/:eventId" element={<GetSelfiePage />} />
        <Route path="/myEventAlbum/selfie/:eventId/:phoneNumber?" element={<MyAlbumPageBySelfie />} />
        <Route path="/myEventAlbum/shared-link/:eventId/:sharedLinkId" element={<MyAlbumPageBySharedLink />} />
        <Route path="/photographers-upload/:eventId" element={<PhotographersImagesUpload />} />
      </Routes>
      <ToastContainer />
    </div>
  );
}

export default App;
