import { Box, Button, IconButton, TextField, Typography } from "@mui/material";
import * as XLSX from 'xlsx';
import { CloudUpload } from "@mui/icons-material";
import { observer } from "mobx-react";
import EventPageStore from "../store";
import { useState } from "react";

interface DropFileProps {

}

const formatData = (workbook: XLSX.WorkBook) => {
    const worksheet = workbook.Sheets[workbook.SheetNames[0]];
    const jsonData = XLSX.utils.sheet_to_json(worksheet);
    return jsonData;
};

const PracticipentsFilePicker: React.FC<DropFileProps> = observer(() => {
    const [fileName, setFileName] = useState("");

    const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement> | FileList) => {
        const file = event instanceof FileList ? event[0] : event.target.files?.[0];
        if (!file) return;

        const reader = new FileReader();
        reader.onload = (e) => {
            const data = new Uint8Array((e.target?.result as ArrayBuffer));
            const workbook = XLSX.read(data, { type: 'array' });

            const formattedData = formatData(workbook);
            EventPageStore.setPracticipents(formattedData.map((practicipant) => {
                return {
                    name: (practicipant as any)["שם מלא"],
                    phoneNumber: (practicipant as any)["מספר נייד"].toString().replace("0", "972"),
                    isActive: false,
                    whatsappImagesUploadedCount: 0
                }
            }))
            setFileName(file.name);
        };
        reader.readAsArrayBuffer(file);
    };


    return <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
            <TextField
                sx={{ flexGrow: 1, ".MuiOutlinedInput-root": { borderRadius: "16px" } }}
                variant="outlined"
                margin="normal"
                value={fileName}
                placeholder="בחר אקסל מוזמנים"
                InputProps={{
                    readOnly: true,
                    endAdornment: (
                        <IconButton
                            color="primary"
                            component="label"
                        >
                            <CloudUpload />
                            <input
                                type="file"
                                onChange={handleFileUpload}
                                hidden
                            />
                        </IconButton>
                    ),
                }}
            />
        </Box>
        <Button variant="outlined" sx={{ alignSelf: 'flex-end', mt: 1 }}>
            הורד תבנית
        </Button>
    </Box>
});

export default PracticipentsFilePicker;