import { Box, Button, CircularProgress, Divider, Fade, Modal, Typography } from "@mui/material";
import EventPageStore from "../store";
import { useQuery } from "react-query";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { observer } from "mobx-react";
import { scanEvent } from "../../../api";

const ScanEventImagesModal: React.FC<{}> = observer(() => {

    const handleClose = () => {
        EventPageStore.setScanEventModalOpen(false);
    }

    const handleEventScan = async () => {
        await scanEvent(EventPageStore.eventId);
        handleClose();
    };

    const { isError, isLoading: isEventScanLoading, refetch: scan } = useQuery(["eventScan"], handleEventScan, {
        enabled: false,
        refetchOnMount: false,
        refetchOnReconnect: false,
        refetchOnWindowFocus: false
    })

    useEffect(() => {
        if (isError)
            toast.error('משהו נכשל בסריקת התמונות מהאירוע', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                theme: "light",
            });
    }, [isError]);

    return (
        <Modal
            open={EventPageStore.scanEventModalOpen}
            onClose={handleClose}
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Box
                sx={{
                    maxWidth: '90vw', // maximum width of 90% of the viewport width
                    maxHeight: '90vh', // maximum height of 90% of the viewport height
                    backgroundColor: 'white',
                    borderRadius: '10px',
                    padding: '20px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    overflow: 'auto' // add a scrollbar if the contents are too large
                }}
            >
                <Typography variant='h5' > ? האם ברצונך לסרוק מחדש תמונות מהאירוע </Typography>
                <Divider sx={{ width: "100%" }} />
                <Box sx={{ display: 'flex', gap: '10px', marginTop: "20px" }}>
                    <Button variant='contained' color='primary' onClick={() => scan()}>
                        {
                            isEventScanLoading ? (
                                <Fade
                                    in={isEventScanLoading}
                                    style={{
                                        transitionDelay: isEventScanLoading ? '800ms' : '0ms',
                                    }}
                                    unmountOnExit
                                >
                                    <CircularProgress color="inherit" size={24} />
                                </Fade>
                            ) : "כן"
                        }
                    </Button>
                    <Button variant='contained' color="error" onClick={handleClose}>לא</Button>
                </Box>
            </Box>
        </Modal>
    );
});

export default ScanEventImagesModal;