import React, { useRef } from "react";
import { Box, Button } from "@mui/material";
import QRCode from "react-qr-code";
import html2canvas from "html2canvas";

interface QrCodeProps {
    url: string;
}

const QrCode: React.FC<QrCodeProps> = ({ url }) => {
    const qrRef = useRef<HTMLDivElement>(null);

    const handleDownload = async () => {
        if (qrRef.current) {
            const canvas = await html2canvas(qrRef.current);
            const link = document.createElement("a");
            link.href = canvas.toDataURL("image/png");
            link.download = "qr-code.png";
            link.click();
        }
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
            <Box ref={qrRef} sx={{ display: 'inline-block', padding: 2, border: '1px solid #ccc', borderRadius: '8px' }}>
                <QRCode value={url} />
            </Box>
            <Button variant="contained" sx={{ marginTop: 2 }} onClick={handleDownload}>
                הורד QR קוד
            </Button>
        </Box>
    );
};

export default QrCode;
